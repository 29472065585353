import { onMount } from 'solid-js';

export default function TermsOfService() {
  onMount(() => {
    window.location.href = 'https://portal.magicdoor.com/terms-of-service.pdf';
  });

  return (
    <div class="mx-auto max-w-container px-4 py-8">
      <p>Redirecting to Terms of Service...</p>
    </div>
  );
}
